
import { Component, Vue } from 'vue-property-decorator';
import BaseUseCases from '@thxnetwork/public/components/BaseUseCases.vue';
import BaseTestimonials from '@thxnetwork/public/components/BaseTestimonials.vue';
import BaseContact from '@thxnetwork/public/components/BaseContact.vue';
import BaseUseCaseContact from '@thxnetwork/public/components/BaseUseCaseContact.vue';

const META_TITLE = '';
const META_DESCRIPTION = '';

@Component({
    metaInfo: {
        title: META_TITLE,
        meta: [
            { name: 'title', content: META_TITLE },
            { name: 'description', content: META_DESCRIPTION },
            { name: 'keywords', content: 'play, business' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: META_TITLE },
            { name: 'twitter:description', content: META_DESCRIPTION },
            { name: 'twitter:image:alt', content: META_TITLE },
            { property: 'og:title', content: META_TITLE },
            { property: 'og:description', content: META_DESCRIPTION },
            { property: 'og:type', content: 'website' },
            { property: 'og:site_name', content: 'Play In Business' },
            { property: 'og:url', content: document.location.href },
        ],
    },
    components: {
        'base-use-case-contact': BaseUseCaseContact,
        'base-testimonials': BaseTestimonials,
        'base-use-cases': BaseUseCases,
        'base-contact': BaseContact,
    },
})
export default class PlayInBusiness extends Vue {}
