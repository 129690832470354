
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import { CMS_URL, GOOGLE_SITE_KEY } from '../config/secrets';

@Component({
    components: {
        VueRecaptcha,
    },
})
export default class Contact extends Vue {
    googleSiteKey = GOOGLE_SITE_KEY;
    isSubmitDisabled = true;
    loading = false;
    error = '';
    success = '';

    firstName = '';
    lastName = '';
    email = '';
    phone = '';
    message = '';

    getValidationState({ dirty, validated, valid = null }: any) {
        return dirty || validated ? valid : null;
    }

    async submit() {
        this.loading = true;

        try {
            const r = await axios({
                url: CMS_URL + '/form-contacts',
                method: 'POST',
                data: {
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    message: this.message,
                },
            });

            if (r.status !== 200) throw new Error();

            this.email = '';
            this.firstName = '';
            this.lastName = '';
            this.phone = '';
            this.message = '';

            this.success = 'THX! We will respond to your message as soon as possible.';
        } catch (error) {
            console.error(error);
            this.error = 'Oops! Something went wrong...';
        } finally {
            this.loading = false;
        }
        return;
    }
}
